import { Controller } from '@hotwired/stimulus'
import TomSelect from 'tom-select'
import { get } from '@rails/request.js'
import template from 'lodash/template'

export default class extends Controller {
  static targets = ['input', 'optionTemplate', 'itemTemplate']
  static values = {
    collection: Array,
    url: String,
    placeholder: String,
    selected: Array,
    valueField: String,
    labelField: String,
    multiple: Boolean,
    searchable: Boolean,
    noResultsText: String,
    clearable: Boolean
  }

  connect () {
    const render = {
      no_results: (data, escape) => {
        return `<div class="no-results">${this.noResultsTextValue} "${escape(data.input)}"</div>`
      }
    }
    if (this.hasOptionTemplateTarget) {
      render.option = template(this.optionTemplateTarget.innerHTML)
    }
    if (this.hasItemTemplateTarget) {
      render.item = template(this.itemTemplateTarget.innerHTML)
    }

    const settings = {
      plugins: {},
      options: this.collectionValue,
      items: this.selectedValue,
      valueField: this.valueFieldValue || 'id',
      labelField: this.labelField(),
      searchField: this.searchField(),
      maxItems: this.multipleValue ? null : 1,
      maxOptions: null,
      highlight: !this.hasUrlValue,
      placeholder: this.placeholderValue,
      controlInput: null,
      dropdownParent: 'body',
      onInitialize: () => {
        this.inputTarget.dispatchEvent(new Event('change'))
      },
      load: this.loadFromUrlFunction(),
      render
    }

    if (this.hasUrlValue || this.searchableValue) {
      settings.plugins.dropdown_input = {}
    }

    if (this.clearableValue) {
      settings.plugins.clear_button = {}
    }

    if (this.multipleValue) {
      settings.plugins.remove_button = {
        label: '✕'
      }
    }

    this.select = new TomSelect(this.inputTarget, settings)
  }

  loadFromUrlFunction () {
    if (!this.hasUrlValue) { return }

    return async (query, callback) => {
      if (query.length < 3) return callback()
      const response = await get(this.urlValue, { responseKind: 'json', contentType: 'application/json', query: { query } })
      if (response.ok) {
        const json = await response.json
        this.select.clear()
        this.select.clearOptions()
        callback(json)
      }
    }
  }

  searchField () {
    if (this.hasUrlValue) {
      return []
    } else {
      return [this.labelField()]
    }
  }

  labelField () {
    return this.labelFieldValue || 'name'
  }
}
