Turbo.StreamActions.show_remote_modal = function () {
  const container = document.querySelector("remote-modal-container");
  container.replaceChildren(this.templateContent);
  const dialog = container.querySelector("dialog")
  dialog.showModal()

  dialog.addEventListener("turbo:submit-end", function (event) {
    if (event.detail.success) {
      dialog.close();
    }
  });
};
