import { Controller } from '@hotwired/stimulus'
import flatpickr from 'flatpickr'
import { Danish } from 'flatpickr/dist/l10n/da.js'

export default class extends Controller {
  connect () {
    const defaultUrl = this.data.get('defaultUrl')
    this.flatPickrInstance = flatpickr(
      this.element,
      {
        dateFormat: 'd-m-Y',
        locale: Danish,
        onChange: (selectedDate, dateStr, instance) => {
          Turbo.visit(`${defaultUrl}?date=${dateStr}`)
        }
      }
    )
  }

  disconnect () {
    this.flatPickrInstance.destroy()
  }
}
