// app/javascript/controllers/direct_upload_controller.js
import { Controller } from "@hotwired/stimulus"
import { DirectUpload } from "@rails/activestorage"

export default class extends Controller {
  static targets = ["dropZone", "fileInput", "progressContainer", "submitButton"]

  connect () {
    this.dropZoneTarget.addEventListener("click", this.handleClick.bind(this));
    this.dropZoneTarget.addEventListener("dragover", this.handleDragOver.bind(this))
    this.dropZoneTarget.addEventListener("dragleave", this.handleDragLeave.bind(this))
    this.dropZoneTarget.addEventListener("drop", this.handleDrop.bind(this))
    this.fileInputTarget.addEventListener("change", this.handleFiles.bind(this))

    this.uploadedFiles = []
    this.filesLeft = 0
    this.uploadId = 0
    this.isFormSubmitted = false;
  }

  handleClick (event) {
    if (this.isFileInputClicked) return;  // Ignore if already clicked

    this.isFileInputClicked = true; // Mark as clicked

    if (event.target !== this.fileInputTarget) {
      this.fileInputTarget.click();
    }

    setTimeout(() => {
      this.isFileInputClicked = false;  // Reset flag after a short delay
    }, 100);  // Adjust delay as needed
  }

  handleDragOver (event) {
    event.preventDefault()
    this.dropZoneTarget.classList.add("drag-over")
  }

  handleDragLeave (event) {
    event.preventDefault()
    this.dropZoneTarget.classList.remove("drag-over")
  }

  handleDrop (event) {
    event.preventDefault()
    this.dropZoneTarget.classList.remove("drag-over")
    const files = event.dataTransfer.files
    this.uploadFiles(files)
  }

  handleFiles (event) {
    event.preventDefault();
    const files = event.target.files
    this.uploadFiles(files)
  }

  uploadFiles (files) {
    this.filesLeft += files.length
    this.disableSubmitButton();
    Array.from(files).forEach(file => this.uploadFile(file))
  }

  uploadFile (file) {
    this.uploadId += 1;
    const uploadId = this.uploadId;
    const url = this.fileInputTarget.dataset.directUploadUrl;

    const progressBar = this.createProgressBar(file, uploadId);
    const container = progressBar.closest(".upload-item");

    const delegate = {
      directUploadWillStoreFileWithXHR: (request) => {
        container.classList.add("uploading");

        request.upload.addEventListener("progress", event => {
          const progress = (event.loaded / event.total) * 100;
          progressBar.style.width = `${progress}%`;
        });
      }
    };

    const upload = new DirectUpload(file, url, delegate);

    upload.create((error, blob) => {
      if (error) {
        console.error("Direct upload error:", error);

        container.classList.remove("uploading");
        container.classList.add("error");
      } else {
        this.appendHiddenField(blob.signed_id);

        container.classList.remove("uploading");
        container.classList.add("completed");
      }
      this.filesLeft--;
      // Clear the file input to prevent form submission with the file
      this.clearFileInput();

      if (this.filesLeft === 0 && !this.isFormSubmitted) {
        this.isFormSubmitted = true;  // Ensure form submits only once
        this.element.submit();  // Manually submit form when all files are uploaded
      }
    });
  }

  clearFileInput () {
    // Clear the file input after handling the direct upload
    this.fileInputTarget.value = '';
  }

  disableSubmitButton () {
    if (this.hasSubmitButtonTarget) {
      this.submitButtonTarget.disabled = true;
    }
  }

  enableSubmitButton () {
    if (this.hasSubmitButtonTarget) {
      this.submitButtonTarget.disabled = false;
    }
  }

  createProgressBar (file, uploadId) {
    const container = document.createElement("div");
    container.id = `direct-upload-${uploadId}`;
    container.classList.add("upload-item");

    const fileInfo = document.createElement("div");
    fileInfo.classList.add("file-info");

    const fileName = document.createElement("p");
    fileName.classList.add("file-name");
    fileName.textContent = file.name;

    fileInfo.appendChild(fileName);

    const progressBarWrapper = document.createElement("div");
    progressBarWrapper.classList.add("progress-bar-wrapper");
    progressBarWrapper.setAttribute("role", "progressbar");

    const progressBar = document.createElement("div");
    progressBar.classList.add("progress-bar");
    progressBar.style.width = "0%";

    progressBarWrapper.appendChild(progressBar);

    container.appendChild(fileInfo);
    container.appendChild(progressBarWrapper);

    this.progressContainerTarget.appendChild(container);

    return progressBar;
  }

  appendHiddenField (signedId) {
    const hiddenField = document.createElement('input')
    hiddenField.type = 'hidden'
    hiddenField.value = signedId
    hiddenField.name = this.fileInputTarget.name
    this.element.appendChild(hiddenField)
  }
}
