import { Controller } from '@hotwired/stimulus'
import flatpickr from 'flatpickr'
import { Danish } from 'flatpickr/dist/l10n/da.js'

export default class extends Controller {
  connect () {
    if (this.hasDatepickerInstance()) return;

    const locale = Danish
    const pickerType = this.data.get('picker-type') || 'date'

    // Determine if we're in a dialog
    const dialog = this.element.closest('dialog')

    // Get the picker options
    const options = this._picker_options(pickerType, locale, dialog)

    this.initializeFlatpickr(options)

    // Add document click event listener to close datepicker on outside click
    document.addEventListener('click', this.handleDocumentClick.bind(this), true)
  }

  disconnect () {
    if (this.shouldDisconnect()) {
      this.destroyFlatpickr()
    }

    // Clean up the document click listener
    document.removeEventListener('click', this.handleDocumentClick.bind(this), true)
  }

  hasDatepickerInstance () {
    return !!this.datepicker_instance // Check if an instance already exists
  }

  initializeFlatpickr (options) {
    if (!this.hasDatepickerInstance()) {
      this.datepicker_instance = flatpickr(this.element, options)
    }
  }

  destroyFlatpickr () {
    if (this.hasDatepickerInstance()) {
      const dateValue = this.element.value
      this.datepicker_instance.destroy()
      this.element.value = dateValue
      this.datepicker_instance = null
    }
  }

  shouldDisconnect () {
    return !document.body.contains(this.element)
  }

  _picker_options (pickerType, locale, dialog) {
    const options = {
      locale,
      disableMobile: true,
      static: true,
      enableTime: pickerType === 'datetime',
      time_24hr: true,
      onChange: this.handleDateChange.bind(this),
      onClose: this.handleClose.bind(this),
    }

    if (dialog) {
      options.appendTo = dialog
      options.positionElement = this.element.parentElement
      options.position = 'below'
    }

    switch (pickerType) {
      case 'date':
        options.dateFormat = 'd-m-Y'
        break
      case 'datetime':
        options.dateFormat = 'd-m-Y H:i'
        options.enableTime = true
        options.time_24hr = true
        break
      default:
        options.dateFormat = 'd-m-Y'
        break
    }

    return options
  }

  handleDateChange (selectedDates, dateStr, instance) {
    if (instance.config.enableTime) {
      this.timeSelected = true
    } else if (this.element.closest('dialog')) {
      instance.close()
    }
  }

  handleClose (selectedDates, dateStr, instance) {
    this.timeSelected = false // Reset timeSelected on close
  }

  handleDocumentClick (event) {
    // Check if datepicker_instance and calendarContainer exist
    if (this.datepicker_instance && this.datepicker_instance.calendarContainer) {
      const dropdown = this.datepicker_instance.calendarContainer
      // Close the datepicker if click happens outside the Flatpickr dropdown
      if (dropdown && !dropdown.contains(event.target) && !this.element.contains(event.target)) {
        this.datepicker_instance.close()
      }
    }
  }
}
